import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/bacalhau.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import gravlaxcover from "../images/Blinis.jpg" // Tell webpack this JS file uses this image
import bacicones from "../images/bac-icones.png" // Tell webpack this JS file uses this image
import bacicones2 from "../images/bac-icones2.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new-bac.png" // Tell webpack this JS file uses this image
import diff1 from "../images/diff-1.png" // Tell webpack this JS file uses this image
import diff2 from "../images/diff-2.png" // Tell webpack this JS file uses this image
import diff3 from "../images/diff-3.png" // Tell webpack this JS file uses this image
import price1 from "../images/price-1.png" // Tell webpack this JS file uses this image
import price2 from "../images/price-2.png" // Tell webpack this JS file uses this image
import price3 from "../images/price-3.png" // Tell webpack this JS file uses this image
import time from "../images/time.png" // Tell webpack this JS file uses this image
import pessoas from "../images/pessoas.png" // Tell webpack this JS file uses this image
import ingrediente from "../images/ingrediente.svg" // Tell webpack this JS file uses this image
import StopMotionGravlax from "../images/StopMotionBacalhauaBraz.mp4"


const Bacalhau = () => (
<Layout>
   <SEO title="Peixe Fácil - Bacalhau" />
   <div className="wrapper-bg-bacalhau">
      <div className="center-col-bg">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>
      </div>
   </div>
   <div id="section-1-bacalhau">
      <div className="wrapper-container">
        
         <div className="titulo absara blue-marine">
            <p>Porção de Bacalhau</p>
         </div>
         <div className="descricao sneak-regular opacity">
            <p> O peixe mais versátil da nossa cozinha, agora ainda mais rápido de confecionar!</p>
         </div>
      </div>
   </div>
   <div id="recipes-section-bacalhau">
      <div className="wrapper-section">
        <div className="col-1">
        <video className="video-class" preload='auto' controls autoPlay loop playsInline muted>
            <source src={StopMotionGravlax} type="video/mp4" />
          </video>
        </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Bacalhau à Brás
                 </p>
                 
              </div>
              <div className="middle">
              <div className="col1">
                <span className="absara"> 2 Pessoas</span><br></br>
              <img src={pessoas} alt="" />

                
                </div>
                <div className="col2">
                <span className="absara"> 30 Minutos</span><br></br>
                <img src={time} alt="" />

                  </div>
                  <div className="col3">
                  <span className="absara"> Dificuldade</span><br></br>
                  <img src={diff2} alt="" />

                  </div>
                  <div className="col4">
                  <span className="absara"> Preço</span><br></br>
                  <img src={price2} alt="" />

                  </div>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                      <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de filetes de Bacalhau Ponto de Sal</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Cebola</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Dente de alho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Pacote pequeno de batata palha</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Azeite Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Ovos</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Azeitonas pretas Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal e pimenta Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Salsa Q/B</span>
                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Numa frigideira salteie em lume brando, a cebola às rodelas e os dentes de alho picados no azeite.</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Adicione o bacalhau e as batatas-palha e misture bem. Deixe as batatas amolecerem um pouco.</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Numa tigela, bata ligeiramente os ovos com um garfo.</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Acrescente os ovos ao bacalhau e envolva até estar bem ligado e os ovos estarem cozinhados.</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Prove e tempere a seu gosto. Sirva, salpicado com a salsa e as azeitonas.</span><br></br>


                     </div>
                     </div>
                 </div>
                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            551
                        </div>
                        <div className="titulo sneak-regular">
                            32
                        </div>
                        <div className="titulo sneak-regular">
                           9
                        </div>
                        <div className="titulo sneak-regular">
                      23
                        </div>
                        <div className="titulo sneak-regular">
                         1
                        </div>
                        <div className="titulo sneak-regular">
                          43
                        </div>
                        <div className="titulo sneak-regular">
                         3
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>

           </div>
        </div>
        </div>
      </div>

      <div className="wrapper-section-2">
        <div className="col-1-mobile">

        </div>
        <div className="col-1">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Bacalhau à Lagareiro
                 </p>
              </div>
              <div className="middle">
              <div className="col1">
                <span className="absara"> 2 Pessoas</span><br></br>
              <img src={pessoas} alt="" />

                
                </div>
                <div className="col2">
                <span className="absara"> 50 Minutos</span><br></br>
                <img src={time} alt="" />

                  </div>
                  <div className="col3">
                  <span className="absara"> Dificuldade</span><br></br>
                  <img src={diff2} alt="" />

                  </div>
                  <div className="col4">
                  <span className="absara"> Preço</span><br></br>
                  <img src={price1} alt="" />

                  </div>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de Bacalhau Ponto de Sal</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">200g Batatinha nova</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">100g Cebola</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">3 Dentes de alho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Louro Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Azeite Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal e pimenta preta Q/B</span><br></br>

                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Lave bem a batata; coloque em água com sal e coza com pele até tenra. Reserve.</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Lamine finamente a cebola e o alho</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Coloque os lombos de bacalhau num tabuleiro de forno; adicione os dentes de alho laminados, louro e regue com azeite abundante; asse no forno a 160ºC por 30 minutos, juntamente com as batatas ligeiramente esmagadas.</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Numa frigideira coloque um fio de azeite; adicione a cebola laminada e deixe refogar até alourar, temperando com sal e pimenta.</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Retire o tabuleiro do forno, junte a cebola refogada. Sirva imediatamente.</span><br></br>



                     </div>
                     </div>
                 </div>
                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            288
                        </div>
                        <div className="titulo sneak-regular">
                            6
                        </div>
                        <div className="titulo sneak-regular">
                           1
                        </div>
                        <div className="titulo sneak-regular">
                      21
                        </div>
                        <div className="titulo sneak-regular">
                         1
                        </div>
                        <div className="titulo sneak-regular">
                          38
                        </div>
                        <div className="titulo sneak-regular">
                         2
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
        <div className="col-2">
 
        </div>
      </div>

      <div className="wrapper-section-3">
      <div className="col-1">
 
 </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Salada de Bacalhau Braseado
              </p>
              </div>
              <div className="middle">
              <div className="col1">
                <span className="absara"> 4 Pessoas</span><br></br>
              <img src={pessoas} alt="" />

                
                </div>
                <div className="col2">
                <span className="absara"> 40 Minutos</span><br></br>
                <img src={time} alt="" />

                  </div>
                  <div className="col3">
                  <span className="absara"> Dificuldade</span><br></br>
                  <img src={diff2} alt="" />

                  </div>
                  <div className="col4">
                  <span className="absara"> Preço</span><br></br>
                  <img src={price2} alt="" />

                  </div>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de Bacalhau Ponto de Sal</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Pimento vermelho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Pimento amarelo </span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Cebola roxa</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">250g Espargos verdes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">50g Tomate cherry</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de rúcula selvagem</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Coentros Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Salsa Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Oregãos Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Azeite Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Vinagre branco Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Dente de alho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal e pimenta preta Q/B</span><br></br>




                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Besunte o bacalhau de azeite e leve a brasear numa grelha juntamente com os pimentos limpos de sementes.</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Entretanto coza os espargos verdes durante 5 minutos em água a ferver e salgada. Retire e arrefeça os mesmos em água fria abundante. Reserve.</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Pique os coentros, a salsa e o alho e misture numa taça juntamente com os orégãos, o azeite e vinagre. Tempere a gosto com sal e pimenta.</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Retire o bacalhau e os pimentos da grelha. Lasque o bacalhau e corte os pimentos em finas lamelas.</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Disponha num prato grande, a rúcula e os espargos verdes cortados na longitude com os tomates cherry cortados em metades.</span><br></br>
                       <span className="span-icon">6 -</span><span className="span-text">Coloque por cima, as lascas de bacalhau, os pimentos e a cebola roxa picada.</span><br></br>
                       <span className="span-icon">7 -</span><span className="span-text">Tempere o preparado</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            307
                        </div>
                        <div className="titulo sneak-regular">
                            14
                        </div>
                        <div className="titulo sneak-regular">
                           2
                        </div>
                        <div className="titulo sneak-regular">
                      15
                        </div>
                        <div className="titulo sneak-regular">
                         1
                        </div>
                        <div className="titulo sneak-regular">
                          31
                        </div>
                        <div className="titulo sneak-regular">
                         5
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
       
      </div>
   </div>
   <div className="wrapper-3-bacalhau">
   <div className="logo-footer">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>

      </div>
      <div className="btn-div">
         <Link to="/" style={{ textDecoration: 'none' }}> <button className="btn sneak-bold">Descubra outros produtos!</button></Link>
      </div>
   </div>


</Layout>
)
export default Bacalhau